<template>
  <v-container fluid class="pa-6">
    <v-row align="center" justify="center">
      <v-col md="6">
        <v-card>
          <v-container class="pa-6">
            <h4 class="text-h5 text-center mb-3 font-weight-light">
              {{ $t('auth.signUp') }}
            </h4>
            <div class="pb-3">{{ $t('auth.completeGroupRegisterText') }}</div>
            <group-register-form
              :email="email"
              :token="token"
            ></group-register-form>
          </v-container>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import GroupRegisterForm from '../components/auth/GroupRegisterForm';

export default {
  name: 'group-sign-up',
  components: {
    GroupRegisterForm
  },
  computed: {
    ...mapGetters(['currentUser', 'isAuthenticated']),
    token() {
      return this.$route.query.grt;
    },
    email() {
      return this.$route.query.email;
    }
  }
};
</script>
